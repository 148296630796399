<script setup>
import Icon from '../components/Icon.vue'
import { $isCartOpen, $supportCode, $cart } from '../store'
import { useStore } from '@nanostores/vue';
import CartDrawerItem from './CartDrawerItem.vue'

const isCartOpen = useStore($isCartOpen);
const supportCode = useStore($supportCode);
const cart = useStore($cart);
</script>

<template>
  <div class="cart-drawer" :class="{ active: isCartOpen }">
    <div class="cart-drawer__overlay" @click="$isCartOpen.set(false)"></div>
    <div class="cart-drawer__inner">
      <div class="cart-drawer__heading">
        <h3 class="cart-drawer__title">Your Cart</h3>
        <a href="#" class="cart-drawer__close" @click.prevent="$isCartOpen.set(false)">
          <Icon type="close" />
        </a>
      </div>
      <div class="cart-drawer__thead">
        <span>PRODUCT</span>
        <span>TOTAL</span>
      </div>
      <div class="cart-drawer__tbody">
        <CartDrawerItem :item="item" v-for="item in cart.items" :key="item.id" />
      </div>
      <div class="cart-drawer__summary">
        <span class="cart-drawer__subtotal">
          <strong>Subtotal</strong>
          <span>${{ cart.subtotal_price }} USD</span>
        </span>
        <span class="cart-drawer__taxes">Taxes & Shipping calculated at checkout.</span>
      </div>
      <div>
        <a :href="`/checkout/${cart.id}`" rel="nofollow" class="cart-drawer__button">PROCEED TO CHECKOUT</a>
      </div>
      <div class="cart-drawer__support">
        <p><strong>Questions?</strong> <a href="/pages/contact-us">Contact Support</a></p>
        <p><strong>Support Code:</strong> {{ supportCode }}</p>
      </div>
      <p class="cart-drawer__discount">
        Gift Cards, Taxes, and Shipping calculated at checkout. <a href="https://verifypass.com/offers/website/hometownherocbd.com" target="_blank">Active military & veterans click here to receive a 15% off military discount!</a>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cart-drawer {
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  top: 0;
  transition: visibility var(--duration-default) ease;
  width: 100vw;
  z-index: 2147483001;
  visibility: hidden;

  &.active {
    visibility: visible;
  }

  .cart-drawer__overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  &.active .cart-drawer__inner {
    transform: translateX(0);
  }
  .cart-drawer__inner {
    max-width: calc(100vw - 3rem);
    transform: translateX(100%);
    transition: transform var(--duration-default) ease;
    width: 32rem;
    background: var(--shades-white, #FFF);
    box-shadow: 0 4px 6px -4px rgba(16, 24, 40, 0.10), 0px 10px 15px -3px rgba(16, 24, 40, 0.10);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 20px 0;

    @media screen and (min-width: 768px) {
      padding: 32px 0;
    }

    & > * {
      padding-left: 20px;
      padding-right: 20px;

      @media screen and (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    .cart-drawer__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;

      @media screen and (min-width: 768px) {
        margin-bottom: 32px;
      }

      .cart-drawer__title {
        color: var(--neutral-1000, #3C3833);
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;

        @media screen and (min-width: 768px) {
          font-size: 32px;
          font-weight: 600;
          line-height: 40px;
        }
      }
    }

    .cart-drawer__thead {
      display: flex;
      justify-content: space-between;
      color: var(--neutral-700, #767370);
      text-transform: uppercase;
      border-bottom: 1px solid var(--neutral-300, #C4C3C1);
      font-size: 12px;
      font-weight: 600;
      line-height: 20px; /* 166.667% */
      letter-spacing: 1.2px;
      padding-bottom: 2px;
      margin-bottom: 19px;

      @media screen and (min-width: 768px) {
        font-size: 14px;
        letter-spacing: 1.4px;
        padding-bottom: 4px;
        margin-bottom: 24px;
      }
    }

    .cart-drawer__tbody {
      flex-grow: 1;
      overflow-y: auto;
    }

    .cart-drawer__summary {
      padding-top: 16px;
      border-top: 1px solid var(--neutral-300, #C4C3C1);

      .cart-drawer__subtotal {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        strong {
          color: var(--neutral-1000, #3C3833);
          font-family: Outfit, serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          display: block;

          @media screen and (min-width: 768px) {
            font-size: 20px;
            line-height: 28px;
          }
        }

        span {
          color: var(--neutral-1000, #3C3833);
          text-align: right;
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;

          @media screen and (min-width: 768px) {
            font-size: 18px;
            line-height: 32px;
          }
        }
      }
      .cart-drawer__taxes {
        color: var(--neutral-700, #767370);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        @media screen and (min-width: 768px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    .cart-drawer__button {
      display: block;
      margin: 24px 0;
      border-radius: 32px;
      background: var(--Orange---terracotta, #DB7E5A);
      box-shadow: 0 4px 6px -4px rgba(16, 24, 40, 0.10), 0px 10px 15px -3px rgba(16, 24, 40, 0.10);
      padding: 18px;
      color: var(--shades-white, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 100% */
      letter-spacing: 1px;
      text-transform: uppercase;
      text-decoration: none;
      border: 0;

      &:hover {
        background: var(--Light-orange, #EFA670);
      }

      @media screen and (min-width: 768px) {
        padding: 20px;
        font-size: 20px;
      }
    }

    .cart-drawer__support {
      p {
        margin: 0;
        color: var(--neutral-1000, #3C3833);
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;

        @media screen and (min-width: 768px) {
          font-size: 16px;
          line-height: 28px; /* 175% */
        }

        strong {
          color: var(--primary-600, #183D4F);
          font-weight: 700;
        }

        a {
          color: var(--neutral-1000, #3C3833);
        }
      }
    }

    .cart-drawer__discount {
      color: var(--neutral-700, #767370);
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;

      @media screen and (min-width: 768px) {
        font-size: 14px;
        line-height: 24px;
      }

      a {
        color: var(--neutral-1000, #3C3833);
      }
    }
  }
}
</style>
